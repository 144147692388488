import dynamic from "next/dynamic";
import { Loader } from "../../src/platform/components/Loader/Loader";
import PlatformLayout from "../../src/platform/layout/PlatformLayout";
const HomeRender = dynamic(() => import("../../src/platform/views/Home/HomeRender"), {
  ssr: false,
  loading: () => <Loader fullHeight fullWidth />,
});

const DatacatalogHomePage = () => {
  return (
    <PlatformLayout isAuthRequired>
      <HomeRender />
    </PlatformLayout>
  );
};

export default DatacatalogHomePage;
